import React from 'react';
import { connect } from 'react-redux';
import classNames from "classnames";
import _ from "lodash";

import buttons from "../../styles/button/button.module.scss";
import list from "../../styles/list/list.module.scss";
import { EmailVerifyIcon } from '@netapp/bxp-design-system-react/icons/2-colors';
import { CheckCircleIcon as CheckCircle } from '@netapp/bxp-design-system-react/icons/monochrome';
import { UserCardIcon } from '@netapp/bxp-design-system-react/icons/2-colors';
import { CheckCircleTwoColorIconsIcon as CheckCircleTwoColorsIcon } from '@netapp/bxp-design-system-react/icons/2-colors';
import { FilesIcon as Files } from "@netapp/bxp-design-system-react/icons/monochrome"
import { NavigationIcon as NevIcon } from "@netapp/bxp-design-system-react/icons/monochrome"

import ActionButton from "../widgets/ActionButton";

import { registerFeature } from '../../middlewares/apiMiddleware';
import federationSetupMiddleWare from "../../middlewares/featureApis/federationSetupMiddleware";
import styles from "./FederationSetup.module.scss";
import Loader from "../../Loader";
import CloudCentralSecondaryHeader from "../headers/CloudCentralSecondaryHeader";
import ExternalLink from "../widgets/ExternalLink";
import CopyTextToClipboardButton from '../copyTextToClipboardButton/copyTextToClipboardButton';
import UploadFileComponent from './UploadFileComponent'
import SelectSupportEmail from './SelectSupportEmail'
import { Heading, Text, Card, FormFieldInputNew, SyntaxHighlighter } from "@netapp/bxp-design-system-react";

const metadataUrl = "https://[domain]/samlp/metadata?connection=[connectionName]";
const appAuthDomain = process.env.REACT_APP_AUTH_DOMAIN;

registerFeature("FEDERATION-SETUP", federationSetupMiddleWare);


export const CreateConnectionButton = ({ createConnection, cancel, disabled }) => (
    _.isFunction(cancel) ? <div className={styles['button-pair']}>
            <button onClick={cancel} className={classNames(styles['cancel-button'], buttons["cancel"])}>Close
            </button>
            <button disabled={disabled} type="submit" onClick={createConnection}
                    className={classNames(styles['submit-button'], buttons["primary"])}>
                Create Connection
            </button>
        </div> :
        <button onClick={createConnection} disabled={disabled}
                className={classNames(styles['submit-button'], buttons["primary"])}>
            Create Connection
        </button>
);

export const UpdateConnectionButtons = ({ updateConnection, cancel, disabled, isEnabled }) => <div className={styles['button-pair']}>
    {!isEnabled && <button type="submit" onClick={cancel} className={classNames(styles['cancel-button'], buttons["cancel"])}>Cancel
    </button>}
    <button disabled={disabled} type="submit" onClick={updateConnection}
            className={classNames(buttons["primary"])}>Update Connection
    </button>
</div>;

const ConnectionCreatedFormComponent = ({ userEmail, toggleUpdateMode, downloadMetadataUrl, connectionName }) => <div className={styles["form"]}>
    <h4>Your connection was created. Let's test it now.</h4>
    {downloadMetadataUrl && <div>
        <p>Some SAML Identity Providers can accept importing metadata directly with all the required information. You can access the metadata for your connection in cloud central <a href={downloadMetadataUrl}
                                                                                                                                                                                      download={`${appAuthDomain.replace(/\./g, '_')}-${connectionName}-metadata.xml`}>here</a>
        </p>
        <br/>
        OR
        <br/>
        <p>Copy the url and download the xml</p>
        <br/>
        <div className={styles["copy-to-clipboard-field"]}>
            <SyntaxHighlighter customStyle={{ background: "rgb(246, 246, 246)", borderRadius: 4, width: '90%' }}>{downloadMetadataUrl}</SyntaxHighlighter>
            <CopyTextToClipboardButton text={downloadMetadataUrl}
                                       Button={() => (<ActionButton className={styles["copy-button"]} action={"copyToClipboard"} category="downLoadFederationMetadata" label="CopyDownLoadFederationMetadata"><Files/></ActionButton>)}>
            </CopyTextToClipboardButton>
        </div>
        <p style={{ fontSize: '12px' }}>*If no additional setup is required go ahead and test your connection</p>
    </div>}
    <p>Right click the button below and click "Open Link in Incognito Window / Private Window." Test your connection by logging in to the app with your organization credentials.</p>
    <a type="button" href={`/redirect-to-federated-connection-test?email=${userEmail}`}
       target="_blank" rel="noopener noreferrer"
       className={classNames(styles['test-button'], buttons["primary"])}>Test Your Connection <span
        style={{ fontSize: 12 }}>(Open in Incognito Window)</span>
    </a>
    <button className={styles["change-my-connection-settings-button"]}
            onClick={toggleUpdateMode}>Change my connection settings
    </button>
    <p className={styles["warning-note"]}>Important: Opening the link in a regular browser tab causes you to log out from the current session. Opening the link in an incognito window or private window works around this limitation.</p>
</div>;

const ConnectionTestedFormComponent = ({ enableConnection, toggleUpdateMode, enableConnectionError, loading }) => <div className={styles["form"]}>
    <h4>Your connection was successfully tested! The last step is to enable the connection.</h4>
    <button disabled={loading} type="button" className={classNames(styles['test-button'], buttons["primary"])}
            onClick={event => enableConnection(event)}>Enable Connection
    </button>
    <button className={styles["change-my-connection-settings-button"]}
            onClick={toggleUpdateMode}>Change my connection settings
    </button>
    {enableConnectionError && <div className={styles["error-message"]}>{enableConnectionError}</div>}
</div>;

const EmailNotVerifiedComponent = ({ resendVerificationEmail, resendVerificationEmailSuccess, resendVerificationEmailError }) => (
    <Card className={styles["email-not-verified"]}>
        <EmailVerifyIcon/>
        <Text bold>
            In order to set up a federated identity connection, you must verify your account first.
        </Text>
        <button onClick={resendVerificationEmail} className={buttons.primary}>
            Send me a Verification Email
        </button>
        {resendVerificationEmailSuccess && <div className={styles["success-message"]}>
            <CheckCircle/>
            We sent you a verification email, please verify your account and refresh this page
        </div>}
        {resendVerificationEmailError && <div className={styles["error-message"]}>
            An error has occurred, please try again or contact support.
        </div>}
    </Card>);

const StartedByOtherUserComponent = ({ creatorUserInfo }) => (<div className={styles["started-by-other-user"]}>
    <UserCardIcon/>
    <p><span
        className={styles["creator-user-email"]}>{creatorUserInfo.creatorUserEmail}</span> has
        already started this federation setup.<br/>
        Only this user can continue with this process.
    </p>

    <p className={styles["contact-support"]}>
        If you have any questions or need help please contact support.
    </p>
</div>);

const ConnectionAlreadyEnabledComponent = ({ creatorUserInfo }) => (
    <div className={styles["connection-already-enabled"]}>
        <CheckCircleTwoColorsIcon/>
        <h3>Your account was already federated!</h3>
        <p>
            <span className={styles["creator-user-email"]}>{creatorUserInfo.creatorUserEmail}</span> has finished this
            setup successfully.
        </p>

        <p className={styles["contact-support"]}>
            If you have any questions or need help please contact support.
        </p>
    </div>);

class CreateAdfsConnectionComponent extends React.Component {
    constructor(props) {
        super(props);
        if (this.props.isCreator && this.props.connection) {
            this.state = this.props.connection.options;
        } else {
            this.state = { fedMetadataName: '', adfsServer: '' };
        }
        this.handleChange = this.handleChange.bind(this);
        this.updateConnection = this.updateConnection.bind(this);
        this.createConnection = this.createConnection.bind(this);
        this.enableConnection = this.enableConnection.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    uploadFile(event) {
        const file = event.target.files[0];

        if (file) {
            const fileReader = new FileReader();
            fileReader.readAsText(file);
            fileReader.onloadend = () => this.setState({
                fedMetadataXmlBody: fileReader.result,
                fedMetadataName: file.name
            });
        }
    }

    createConnection(event) {
        event.preventDefault();
        this.props.createConnection({
            strategy: 'adfs',
            params: {
                adfsServer: this.state.adfsServer,
                fedMetadataXml: this.state.fedMetadataXmlBody
            }
        });
    }

    enableConnection(event) {
        event.preventDefault();
        this.props.enableConnection({ connectionId: this.props.connection.connectionId });
    }

    updateConnection(event) {
        event.preventDefault();
        this.props.updateConnection({
            strategy: 'adfs',
            connectionId: this.props.connection.connectionId,
            params: {
                adfsServer: this.state.adfsServer,
                fedMetadataXml: this.state.fedMetadataXmlBody
            }
        });
    }

    render() {
        return (<div className={styles["create-connection-instructions-and-form"]}>
            <div className={styles["section-header"]}>ADFS</div>
            <div className={styles["instructions"]}>
                <h4>
                    Before you begin
                </h4>
                <ul className={list["chevron-ul"]}>
                    <li>You should have an existing ADFS server.</li>
                    <li>You should have permission to configure the ADFS server to enable identity federation with
                        our authentication and authorization service provider (Auth0).
                    </li>
                </ul>
                <h4>
                    Steps
                </h4>
                <ol className={list["number-ol"]}>
                    <li>
                        <p>Configure your ADFS server to enable identity federation with our authentication provider
                            (domain: https://{this.props.domain}).</p>

                        <p>
                            <a target="_blank" rel="noreferrer noopener" href="https://auth0.com/docs/connections/enterprise/adfs">View instructions and an explanation for configuring the ADFS server. (skip auth0 configuration)</a>
                        </p>

                        <p>You can configure the ADFS server automatically by using the following PowerShell script:</p>
                        <div>
                            <code className={styles["language-powershell"]}>
                                (<span
                                className={styles["built_in"]}>new-object</span>{" Net.WebClient -property @{Encoding = [Text.Encoding]::UTF8}).DownloadString"}(<span
                                className={styles["string"]}>"https://raw.github.com/auth0/adfs-auth0/master/adfs.ps1"</span>)
                                |
                                iex <br/>
                                AddRelyingParty <span
                                className={styles["string"]}>"urn:auth0:{this.props.tenant}"</span> <span
                                className={styles["string"]}>"https://{this.props.domain}/login/callback"</span>
                            </code>
                        </div>

                        <p>If you choose to set up the ADFS configuration manually, follow these instructions and use the following values:<a
                            target="_blank" rel="noreferrer noopener" href="https://auth0.com/docs/connections/enterprise/adfs#manual-setup">these
                            instruction</a> and use the following values:</p>
                        <ul style={{ marginLeft: 45 }}>
                            <li>YOUR_TENANT: <span className={styles["code"]}>{this.props.tenant}</span></li>
                            <li>YOUR_AUTH0_DOMAIN: <span className={styles["code"]}>{this.props.domain}</span></li>
                        </ul>
                    </li>
                    <li><p>After you configure your ADFS server, fill out the form below to create a connection.</p></li>
                    <li><p>Complete the configuration on your identity server using the details that displayed after completing the form below.</p></li>
                    <li><p>Test the connection by clicking <b>Test</b> below. The <b>Test</b> button appears only after connection setup is complete.</p></li>
                    <li><p>After the test is complete, refresh this page.</p></li>
                    <li><p>Enable the connection by clicking <b>Enable Connection</b> below. The <b>Enable Connection</b> button appears only after the test is complete.</p></li>
                    <p>If you encounter any problems or questions, please reach out to <a href="https://mysupport.netapp.com/site/help" target="_blank" rel="noopener noreferrer">support</a>.</p>
                </ol>
            </div>
            {(this.props.status === 'NO-CONNECTION-YET' || this.props.status === 'UPDATE-CONNECTION' || this.props.status === "CONNECTION-ENABLED") &&
                <div className={styles["form"]}>
                    <h4>To {this.props.connection ? 'update' : 'create'} your connection you can either provide the ADFS URL or upload the federation metadata
                        file:</h4>
                    <form>
                        <FormFieldInputNew
                            type="text"
                            name="adfsServer"
                            label="ADFS URL"
                            value={this.state.adfsServer}
                            onChange={this.handleChange}
                            placeholder="http://your-adfs-url.com/"
                        />
                        <div className={styles["or-divider"]}><span>OR</span></div>
                        <UploadFileComponent name="fedMetadataName"
                                             label="Upload a Federation Metadata File"
                                             value={this.state.fedMetadataName}
                                             uploadFile={this.uploadFile}
                                             placeholder="Metadata file"/>
                        {!this.props.connection && <CreateConnectionButton disabled={!this.props.isCreator || this.props.loading} createConnection={this.createConnection}/>}
                        {this.props.connection &&
                            <UpdateConnectionButtons isEnabled={this.props.status === "CONNECTION-ENABLED"} disabled={!this.props.isCreator || this.props.loading} updateConnection={this.updateConnection}
                                                     cancel={this.props.toggleUpdateMode}/>}
                        {this.props.createConnectionError && <div className={styles["error-message"]}>{this.props.createConnectionError}</div>}
                        {this.props.updateConnectionError && <div className={styles["error-message"]}>{this.props.updateConnectionError}</div>}
                    </form>
                </div>}
            {this.props.status === 'CONNECTION-CREATED' &&
                <ConnectionCreatedFormComponent userEmail={this.props.userEmail}
                                                toggleUpdateMode={this.props.toggleUpdateMode}/>}
            {this.props.status === 'TEST-SUCCEEDED' &&
                <ConnectionTestedFormComponent enableConnection={this.enableConnection}
                                               toggleUpdateMode={this.props.toggleUpdateMode}
                                               enableConnectionError={this.props.enableConnectionError}
                                               loading={this.props.loading}/>}

        </div>);
    }
}

class CreateAzureAdConnectionComponent extends React.Component {
    constructor(props) {
        super(props);
        if (this.props.isCreator && this.props.connection) {
            const { client_id: clientId, client_secret: clientSecret, tenant_domain: tenantDomain } = this.props.connection.options;
            this.state = { clientId, clientSecret, tenantDomain }
        } else {
            this.state = { clientId: '', clientSecret: '', tenantDomain: '' };
        }
        this.handleChange = this.handleChange.bind(this);
        this.updateConnection = this.updateConnection.bind(this);
        this.enableConnection = this.enableConnection.bind(this);
        this.createConnection = this.createConnection.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    createConnection(event) {
        event.preventDefault();
        this.props.createConnection({
            strategy: 'waad',
            params: {
                clientId: this.state.clientId,
                clientSecret: this.state.clientSecret,
                tenantDomain: this.state.tenantDomain
            }
        });
    }

    enableConnection(event) {
        event.preventDefault();
        this.props.enableConnection({ connectionId: this.props.connection.connectionId });
    }

    updateConnection(event) {
        event.preventDefault();
        this.props.updateConnection({
            strategy: 'waad',
            connectionId: this.props.connection.connectionId,
            params: {
                clientId: this.state.clientId,
                clientSecret: this.state.clientSecret,
                tenantDomain: this.state.tenantDomain
            }
        });
    }

    render() {
        return <div className={styles["create-connection-instructions-and-form"]}>
            <div className={styles["section-header"]}>Azure AD</div>

            <div className={styles["instructions"]}>
                <h4>Before you begin</h4>
                <ul className={list["chevron-ul"]}>
                    <li>You should have set up Microsoft Azure Active Directory</li>
                    <li>You should have permission to create a new application in your Azure Active Directory</li>
                </ul>
                <h4>
                    Steps
                </h4>
                <ol className={list["number-ol"]}>
                    <li>
                        <p>Create a new application in Azure Active Directory that will be configured to allow
                            connections from Cloud Central's authentication provider.</p>

                        <p><a target="_blank" rel="noreferrer noopener" href="https://auth0.com/docs/connections/enterprise/azure-active-directory/v2">View instructions for creating the application. (skip auth0 configuration)</a></p>

                        <p>When you create the application, be sure to copy the value of the authentication key.
                            You need to fill it in the form below.
                            Note that Auth0 refers to this key value as the Client Secret.</p>

                        <p>When setting up the application and its permissions, use the following values:</p>
                        <ul style={{ marginLeft: 45 }}>
                            <li>Sign-on URL: <span className={styles["code"]}>{window.location.origin}</span></li>
                            <li>Reply URL: <span className={styles["code"]}>https://{this.props.domain}/login/callback</span></li>
                        </ul>
                    </li>
                    <li><p>After you configure Azure Active Directory, fill out the form below to create a connection.</p></li>
                    <li><p>Complete the configuration on your identity server using the details that displayed after completing the form below.</p></li>
                    <li><p>Test the connection by clicking <b>Test</b> below. The <b>Test</b> button appears only after connection setup is complete.</p></li>
                    <li><p>After the test is complete, refresh this page.</p></li>
                    <li><p>Enable the connection by clicking <b>Enable Connection</b> below. The <b>Enable Connection</b> button appears only after the test is complete.</p></li>
                    <p>If you encounter any problems or questions, visit support at <a href="https://mysupport.netapp.com/site/help" target="_blank" rel="noopener noreferrer">https://mysupport.netapp.com/site/help</a>.</p>
                </ol>
            </div>
            {(this.props.status === 'NO-CONNECTION-YET' || this.props.status === 'UPDATE-CONNECTION' || this.props.status === "CONNECTION-ENABLED") &&
                <div className={styles["form"]}>
                    <h4>To {this.props.connection ? 'update' : 'create'} your connection please fill the details of your Azure AD:</h4>
                    <form>
                        <FormFieldInputNew
                            type="text"
                            name="clientId"
                            label="Client Id"
                            value={this.state.clientId}
                            onChange={this.handleChange}
                            autoComplete="off"
                        />
                        <div className={styles["sub-label"]}><ExternalLink linkKey="auth0Docs.azureAdConnectionDocs" newTab>How
                            to obtain Client ID?</ExternalLink></div>
                        <FormFieldInputNew
                            type="text"
                            name="clientSecret"
                            label="Client Secret"
                            value={this.state.clientSecret}
                            onChange={this.handleChange}
                            autoComplete="off"
                        />
                        <FormFieldInputNew
                            type="text"
                            name="tenantDomain"
                            label="Microsoft Azure AD Domain"
                            value={this.state.tenantDomain}
                            onChange={this.handleChange}
                            placeholder="your-waad-domain.com"
                        />
                        {!this.props.connection && <CreateConnectionButton disabled={!this.props.isCreator || this.props.loading} createConnection={this.createConnection}/>}
                        {this.props.connection && <UpdateConnectionButtons isEnabled={this.props.status === "CONNECTION-ENABLED"} disabled={!this.props.isCreator || this.props.loading} updateConnection={this.updateConnection}
                                                                           cancel={this.props.toggleUpdateMode}/>}
                    </form>
                </div>}
            {this.props.status === 'CONNECTION-CREATED' &&
                <ConnectionCreatedFormComponent userEmail={this.props.userEmail}
                                                toggleUpdateMode={this.props.toggleUpdateMode}/>}
            {this.props.status === 'TEST-SUCCEEDED' &&
                <ConnectionTestedFormComponent enableConnection={this.enableConnection}
                                               toggleUpdateMode={this.props.toggleUpdateMode}
                                               enableConnectionError={this.props.enableConnectionError}
                                               loading={this.props.loading}/>}
        </div>;
    }
}

class CreateSamlConnectionComponent extends React.Component {
    constructor(props) {
        super(props);
        if (this.props.isCreator && this.props.connection) {
            this.state = this.props.connection.options;
            this.state.signingCertFileName = '(Certificate Uploaded)';
        } else {
            this.state = { signInEndpoint: '', signOutEndpoint: '', signingCert: '', signingCertFileName: '' };
        }
        this.handleChange = this.handleChange.bind(this);
        this.updateConnection = this.updateConnection.bind(this);
        this.createConnection = this.createConnection.bind(this);
        this.enableConnection = this.enableConnection.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    uploadFile(event) {
        const file = event.target.files[0];
        if (file) {
            const fileReader = new FileReader();
            fileReader.readAsText(file);
            fileReader.onloadend = () => {
                this.setState({
                    signingCert: fileReader.result.replace(/(\\n)/gm, "\n"),
                    signingCertFileName: file.name
                });
            };
        }
    }

    createConnection(event) {
        event.preventDefault();
        this.props.createConnection({
            strategy: 'samlp',
            params: {
                signInEndpoint: this.state.signInEndpoint,
                signOutEndpoint: this.state.signOutEndpoint,
                signingCert: this.state.signingCert
            }
        });
    }

    enableConnection(event) {
        event.preventDefault();
        this.props.enableConnection({ connectionId: this.props.connection.connectionId });
    }

    updateConnection(event) {
        event.preventDefault();
        this.props.updateConnection({
            strategy: 'samlp',
            connectionId: this.props.connection.connectionId,
            params: {
                signInEndpoint: this.state.signInEndpoint,
                signOutEndpoint: this.state.signOutEndpoint,
                signingCert: this.state.signingCert
            }
        });
    }

    render() {
        const userEmailDomain = this.props.userEmail.split('@')[1];
        const kebabCaseEmailDomain = _.kebabCase(userEmailDomain);

        return <div className={styles["create-connection-instructions-and-form"]}>
            <div className={styles["section-header"]}>SAML</div>

            <div className={styles["instructions"]}>
                <h4>Before you begin</h4>
                <ul className={list["chevron-ul"]}>
                    <li>You should have an existing SAML identity provider (IdP).</li>
                    <li>You should have the required access to configure a new connection with your SAML IdP.</li>
                </ul>
                <h4>
                    Steps
                </h4>
                <ol className={list["number-ol"]}>
                    <li>
                        <p>Create a new connection with your SAML IdP. This procedure varies depending on your SAML IdP.
                            The following are links to documentation for several prevalent providers, as well as <a
                                target="_blank" rel="noreferrer noopener" href="https://auth0.com/docs/protocols/saml/saml-sp-generic">generic instructions (skip auth0 configuration)</a> if
                            your identity provider does not appear in the list:</p>

                        <ul style={{ marginLeft: 45 }}>
                            <li><a href="https://auth0.com/docs/protocols/saml-protocol/saml-configuration-options/configure-adfs-saml-connections" target="_blank"
                                   rel="noopener noreferrer">ADFS</a></li>
                            <li><a href="https://auth0.com/docs/protocols/saml-protocol/saml-configuration-options/configure-okta-as-saml-identity-provider" target="_blank"
                                   rel="noopener noreferrer">Okta</a></li>
                            <li><a href="https://auth0.com/docs/authenticate/protocols/saml/saml-sso-integrations/configure-auth0-saml-service-provider/configure-onelogin-as-saml-identity-provider" target="_blank"
                                   rel="noopener noreferrer">OneLogin</a></li>
                            <li><a href="https://auth0.com/docs/authenticate/protocols/saml/saml-sso-integrations/configure-auth0-saml-service-provider/configure-pingfederate-as-saml-identity-provider" target="_blank"
                                   rel="noopener noreferrer">Ping7</a></li>
                            <li><a href="https://auth0.com/docs/protocols/saml-protocol/saml-configuration-options/configure-salesforce-as-saml-identity-provider"
                                   target="_blank" rel="noopener noreferrer">SalesForce</a></li>
                            <li><a href="https://auth0.com/docs/protocols/saml-protocol/saml-configuration-options/configure-siteminder-as-saml-identity-provider" target="_blank"
                                   rel="noopener noreferrer">SiteMinder</a></li>
                            <li><a href="https://auth0.com/docs/protocols/saml-protocol/saml-configuration-options/configure-ssocircle-as-saml-identity-provider" target="_blank"
                                   rel="noopener noreferrer">SSOCircle</a></li>
                        </ul>

                    </li>

                    <li>
                        <p>Ensure that your SAML idP have attribute statement "email" (in lowercase letters) with the user email. The SAML response should include the following:</p>
                        <div>
                            <code className={styles["language-xml"]}>
                                <span>&lt;saml:AttributeStatement <span
                                    className={styles["attribute-name"]}>xmlns:x500</span>=<span
                                    className={styles["attribute-value"]}>"urn:oasis:names:tc:SAML:2.0:profiles:attribute:X500"</span> <span
                                    className={styles["attribute-name"]}>xmlns:xs</span>=<span
                                    className={styles["attribute-value"]}>"http://www.w3.org/2001/XMLSchema"</span> <span
                                    className={styles["attribute-name"]}>xmlns:xsi</span>=<span
                                    className={styles["attribute-value"]}>"http://www.w3.org/2001/XMLSchema-instance"</span>&gt;
                                        </span><br/>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&lt;saml:Attribute <span
                                    className={styles["attribute-name"]}>Name</span>=<span
                                    className={styles["attribute-value"]}>"email"</span> <span
                                    className={styles["attribute-name"]}>NameFormat</span>=<span
                                    className={styles["attribute-value"]}>"urn:oasis:names:tc:SAML:1.1:nameid-format:X509SubjectName"</span>&gt;</span><br/>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;saml:AttributeValue <span
                                    className={styles["attribute-name"]}>xsi:type</span>=<span
                                    className={styles["attribute-value"]}>"xs:string"</span>&gt;<span
                                    className={styles["text"]}>email@domain.com</span>&lt;/saml:AttributeValue&gt;</span><br/>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&lt;/saml:Attribute&gt;</span><br/>
                                <span>&lt;/saml:AttributeStatement&gt;</span><br/>
                            </code>
                        </div>
                    </li>
                    <li><p>After you configure identity provider, fill out the form below to create a connection.</p></li>
                    <li>
                        <p>Complete the configuration on your identity server using the following information:
                            <ul style={{ marginLeft: 45 }}>
                                <li>"Audience" or "Entity ID": <span
                                    className={styles["code"]}>urn:auth0:{this.props.tenant}:fed-{kebabCaseEmailDomain}-samlp</span>
                                </li>
                                Note that connection name is available when you create the connection.
                                For example: urn:auth0:netapp-cloud-account:fed-NetAppfedcon-saml
                                <li>"Reply URL" or "Assertion Consumer Service (ACS) URL": <span
                                    className={styles["code"]}>https://{this.props.domain}/login/callback</span></li>
                                <li>"Logout URL":<span
                                    className={styles["code"]}>https://{this.props.domain}/logout</span></li>
                            </ul>
                        </p>
                    </li>
                    <li><p>Test the connection by clicking <b>Test</b> below. The <b>Test</b> button appears only after connection setup is complete.</p></li>
                    <li><p>After the test is complete, refresh this page.</p></li>
                    <li><p>Enable the connection by clicking <b>Enable Connection</b> below. The <b>Enable Connection</b> button appears only after the test is complete.</p></li>
                    <p>If you encounter any problems or questions, visit support at <a
                        href="https://mysupport.netapp.com/site/help" target="_blank" rel="noopener noreferrer">https://mysupport.netapp.com/site/help</a>.</p>
                </ol>
            </div>
            {(this.props.status === 'NO-CONNECTION-YET' || this.props.status === 'UPDATE-CONNECTION' || this.props.status === "CONNECTION-ENABLED") &&
                <div className={styles["form"]}>
                    <h4>To {this.props.connection ? 'update' : 'create'} your connection please fill the details of your SAML provider:</h4>
                    <form>
                        <FormFieldInputNew
                            type="text"
                            name="signInEndpoint"
                            label="Sign In URL"
                            value={this.state.signInEndpoint}
                            onChange={this.handleChange}
                            placeholder="https://saml-identity-provider/login"
                        />
                        <UploadFileComponent name="signingCert"
                                             label="X509 Signing Certificate"
                                             value={this.state.signingCertFileName}
                                             uploadFile={this.uploadFile}/>
                        <div className={styles["sub-label"]}>Identity Provider public key in PEM or CER format</div>
                        <FormFieldInputNew
                            type="text"
                            name="signOutEndpoint"
                            label="Sign Out URL (Optional)"
                            value={this.state.signOutEndpoint}
                            onChange={this.handleChange}
                            placeholder="https://saml-identity-provider/logout"
                        />
                        {!this.props.connection && <CreateConnectionButton disabled={!this.props.isCreator || this.props.loading} createConnection={this.createConnection}/>}
                        {this.props.connection && <UpdateConnectionButtons isEnabled={this.props.status === "CONNECTION-ENABLED"} disabled={!this.props.isCreator || this.props.loading} updateConnection={this.updateConnection}
                                                                           cancel={this.props.toggleUpdateMode}/>}
                        {this.props.createConnectionError && <div className={styles["error-message"]}>{this.props.createConnectionError}</div>}
                        {this.props.updateConnectionError && <div className={styles["error-message"]}>{this.props.updateConnectionError}</div>}
                    </form>
                </div>}
            {this.props.status === 'CONNECTION-CREATED' &&
                <ConnectionCreatedFormComponent userEmail={this.props.userEmail}
                                                toggleUpdateMode={this.props.toggleUpdateMode}
                                                downloadMetadataUrl={metadataUrl.replace("[domain]", appAuthDomain).replace("[connectionName]", this.props.connection.name)}
                                                connectionName={this.props.connection.name}/>}
            {this.props.status === 'TEST-SUCCEEDED' &&
                <ConnectionTestedFormComponent enableConnection={this.enableConnection}
                                               toggleUpdateMode={this.props.toggleUpdateMode}
                                               enableConnectionError={this.props.enableConnectionError}
                                               loading={this.props.loading}/>}
        </div>;
    }
}

class CreatePingFederateConnectionComponent extends React.Component {
    constructor(props) {
        super(props);
        if (this.props.isCreator && this.props.connection) {
            this.state = this.props.connection.options;
            this.state.signingCertFileName = '(Certificate Uploaded)';
        } else {
            this.state = { pingFederateBaseUrl: '', signingCert: '', signingCertFileName: '' };
        }
        this.handleChange = this.handleChange.bind(this);
        this.updateConnection = this.updateConnection.bind(this);
        this.createConnection = this.createConnection.bind(this);
        this.enableConnection = this.enableConnection.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    uploadFile(event) {
        const file = event.target.files[0];
        if (file) {
            const fileReader = new FileReader();
            fileReader.readAsText(file);
            fileReader.onloadend = () => {
                this.setState({
                    signingCert: fileReader.result.replace(/(\\n)/gm, "\n"),
                    signingCertFileName: file.name
                });
            };
        }
    }

    createConnection(event) {
        event.preventDefault();
        this.props.createConnection({
            strategy: 'pingfederate',
            params: {
                pingFederateBaseUrl: this.state.pingFederateBaseUrl,
                signingCert: this.state.signingCert
            }
        });
    }

    enableConnection(event) {
        event.preventDefault();
        this.props.enableConnection({ connectionId: this.props.connection.connectionId });
    }

    updateConnection(event) {
        event.preventDefault();
        this.props.updateConnection({
            strategy: 'pingfederate',
            connectionId: this.props.connection.connectionId,
            params: {
                pingFederateBaseUrl: this.state.pingFederateBaseUrl,
                signingCert: this.state.signingCert
            }
        });
    }

    render() {
        const userEmailDomain = this.props.userEmail.split('@')[1];
        const kebabCaseEmailDomain = _.kebabCase(userEmailDomain);

        return <div className={styles["create-connection-instructions-and-form"]}>
            <div className={styles["section-header"]}>Ping Federation</div>

            <div className={styles["instructions"]}>
                <h4>Before you begin</h4>
                <ul className={list["chevron-ul"]}>
                    <li>You should have an existing Ping federation server.</li>
                    <li>You should have the required access to configure a new connection with your Ping Federation IdP.</li>
                </ul>
                <h4>Steps</h4>
                <ol className={list["number-ol"]}>
                    <li><p>Create a new connection with your Ping Federation IdP.
                        <a style={{ marginLeft: 8 }} target="_blank" rel="noreferrer noopener" href="https://auth0.com/docs/authenticate/identity-providers/enterprise-identity-providers/ping-federate">View instructions and an explanation
                            for configuring the Ping Federation server. (skip auth0 configuration)</a>
                    </p></li>
                    <li>
                        <p>Ensure that your SAML idP have attribute statement "email" (in lowercase letters) with the user email. The SAML response should include the following:</p>
                        <div>
                            <code className={styles["language-xml"]}>
                                <span>&lt;saml:AttributeStatement <span
                                    className={styles["attribute-name"]}>xmlns:x500</span>=<span
                                    className={styles["attribute-value"]}>"urn:oasis:names:tc:SAML:2.0:profiles:attribute:X500"</span> <span
                                    className={styles["attribute-name"]}>xmlns:xs</span>=<span
                                    className={styles["attribute-value"]}>"http://www.w3.org/2001/XMLSchema"</span> <span
                                    className={styles["attribute-name"]}>xmlns:xsi</span>=<span
                                    className={styles["attribute-value"]}>"http://www.w3.org/2001/XMLSchema-instance"</span>&gt;
                                        </span><br/>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&lt;saml:Attribute <span
                                    className={styles["attribute-name"]}>Name</span>=<span
                                    className={styles["attribute-value"]}>"email"</span> <span
                                    className={styles["attribute-name"]}>NameFormat</span>=<span
                                    className={styles["attribute-value"]}>"urn:oasis:names:tc:SAML:1.1:nameid-format:X509SubjectName"</span>&gt;</span><br/>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;saml:AttributeValue <span
                                    className={styles["attribute-name"]}>xsi:type</span>=<span
                                    className={styles["attribute-value"]}>"xs:string"</span>&gt;<span
                                    className={styles["text"]}>email@domain.com</span>&lt;/saml:AttributeValue&gt;</span><br/>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&lt;/saml:Attribute&gt;</span><br/>
                                <span>&lt;/saml:AttributeStatement&gt;</span><br/>
                            </code>
                        </div>
                    </li>
                    <li><p>After you configure identity provider, fill out the form below to create a connection.</p></li>
                    <li>
                        <p>Complete the configuration on your identity server using the following information:
                            <ul style={{ marginLeft: 45 }}>
                                <li>"Audience" or "Entity ID": <span
                                    className={styles["code"]}>urn:auth0:{this.props.tenant}:fed-{kebabCaseEmailDomain}-pingfederate</span>
                                </li>
                                Note that connection name is available when you create the connection.
                                For example: urn:auth0:netapp-cloud-account:fed-NetAppfedcon-saml
                                <li>"Reply URL" or "Assertion Consumer Service (ACS) URL": <span
                                    className={styles["code"]}>https://{this.props.domain}/login/callback</span></li>
                                <li>"Logout URL":<span
                                    className={styles["code"]}>https://{this.props.domain}/logout</span></li>
                            </ul>
                        </p>
                    </li>
                    <li><p>Test the connection by clicking <b>Test</b> below. The <b>Test</b> button appears only after connection setup is complete.</p></li>
                    <li><p>After the test is complete, refresh this page.</p></li>
                    <li><p>Enable the connection by clicking <b>Enable Connection</b> below. The <b>Enable Connection</b> button appears only after the test is complete.</p></li>
                    <p>If you encounter any problems or questions, visit support at <a
                        href="https://mysupport.netapp.com/site/help" target="_blank" rel="noopener noreferrer">https://mysupport.netapp.com/site/help</a>.</p>
                </ol>
            </div>
            {(this.props.status === 'NO-CONNECTION-YET' || this.props.status === 'UPDATE-CONNECTION' || this.props.status === "CONNECTION-ENABLED") &&
                <div className={styles["form"]}>
                    <h4>To {this.props.connection ? 'update' : 'create'} your connection please fill the details of your Ping federation provider:</h4>
                    <form>
                        <FormFieldInputNew
                            type="text"
                            name="pingFederateBaseUrl"
                            label="PingFederate Server URL"
                            value={this.state.pingFederateBaseUrl}
                            onChange={this.handleChange}
                            placeholder="https://pingFederate.example.com"
                        />
                        <UploadFileComponent name="signingCert"
                                             label="X509 Signing Certificate"
                                             value={this.state.signingCertFileName}
                                             uploadFile={this.uploadFile}/>
                        <div className={styles["sub-label"]}>Identity Provider public key in PEM or CER format</div>
                        {!this.props.connection && <CreateConnectionButton disabled={!this.props.isCreator || this.props.loading} createConnection={this.createConnection}/>}
                        {this.props.connection && <UpdateConnectionButtons isEnabled={this.props.status === "CONNECTION-ENABLED"} disabled={!this.props.isCreator || this.props.loading} updateConnection={this.updateConnection}
                                                                           cancel={this.props.toggleUpdateMode}/>}
                        {this.props.createConnectionError && <div className={styles["error-message"]}>{this.props.createConnectionError}</div>}
                        {this.props.updateConnectionError && <div className={styles["error-message"]}>{this.props.updateConnectionError}</div>}
                    </form>
                </div>}
            {this.props.status === 'CONNECTION-CREATED' &&
                <ConnectionCreatedFormComponent userEmail={this.props.userEmail}
                                                toggleUpdateMode={this.props.toggleUpdateMode}
                                                downloadMetadataUrl={metadataUrl.replace("[domain]", appAuthDomain).replace("[connectionName]", this.props.connection.name)}
                                                connectionName={this.props.connection.name}/>}
            {this.props.status === 'TEST-SUCCEEDED' &&
                <ConnectionTestedFormComponent enableConnection={this.enableConnection}
                                               toggleUpdateMode={this.props.toggleUpdateMode}
                                               enableConnectionError={this.props.enableConnectionError}
                                               loading={this.props.loading}/>}
        </div>;
    }
}

const CreateConnectionComponent = ({
                                       connection, userEmail, status, createConnection, updateConnection,
                                       enableConnection, activeStrategy, setActiveStrategy, toggleUpdateMode,
                                       createConnectionError, updateConnectionError, enableConnectionError, isCreator, loading, showVerifyEmail, resendVerificationEmail, resendVerificationEmailSuccess, resendVerificationEmailError
                                   }) => {
    const { domain } = window.__auth__;
    const tenant = window.__auth__.domain.replace(".auth0.com", "");

    return (<div className={styles["create-connection-component"]}>
        <Heading color="text-title" level={2} center>Identity federation with NetApp BlueXP</Heading>

        <p>Identity federation enables you to manage access to your hybrid cloud resources centrally. With identity federation, you can use single sign-on to access your NetApp BlueXP accounts using credentials from your corporate
            directory. Identity federation uses open standards, such as Security Assertion Markup Language 2.0 (SAML) and OpenID Connect (OIDC).</p>
        <ExternalLink href={'https://docs.netapp.com/us-en/cloud-manager-setup-admin/concept-federation.html'} className={styles.link} newTab>Learn how to prepare for identity federation setup.<NevIcon/></ExternalLink>
        {showVerifyEmail &&
            <EmailNotVerifiedComponent resendVerificationEmail={resendVerificationEmail}
                                       resendVerificationEmailSuccess={resendVerificationEmailSuccess}
                                       resendVerificationEmailError={resendVerificationEmailError}/>}
        <Heading color="text-title" style={{ 'marginTop': 8 }} level={3}>Before you get started</Heading>
        <p>Currently, we support identity federation with Active Directory Federation Services (ADFS), Microsoft Azure Active Directory (AD), Security Assertion Markup Language (SAML) and Ping Federation.</p>
        <p>Before you get started, ensure that the email address that you used to sign into BlueXP
            matches the email from your corporate directory (you are signed into BlueXP with this email: {userEmail}).
            If the email addresses don't match, then we can't sync your BlueXP user account with your corporate user account.
            Note that group email addresses aren’t supported.</p>
        <p>
            <div>NetApp Cloud supports only Service Provider Initiated (SP-initiated) SSO.</div>
            Referred to as NetApp-initiated SSO, this option gives the end users the ability to sign into the NetApp Login page and then sends an authorization request to the Identify Provider (e.g., Okta, Ping, or Microsoft Azure AD).
            Once the IdP authenticates the user's identify, the user is logged into NetApp.
        </p>
        <p>Note: Identity Provider Initiated (IdP-initiated) SSO is not supported.</p>
        {!showVerifyEmail && <>
            <h3>Select one method to create federation connection with:</h3>
            <div className={styles["protocol-buttons-box"]}>
                <button className={classNames(styles["protocol-button"], activeStrategy === 'adfs' && styles['active'])}
                        onClick={() => setActiveStrategy({ strategy: 'adfs' })}
                        disabled={!userEmail || (connection && isCreator)}>ADFS
                </button>
                <button className={classNames(styles["protocol-button"], activeStrategy === 'waad' && styles['active'])}
                        onClick={() => setActiveStrategy({ strategy: 'waad' })}
                        disabled={!userEmail || (connection && isCreator)}>Microsoft Azure AD
                </button>
                <button className={classNames(styles["protocol-button"], activeStrategy === 'samlp' && styles['active'])}
                        onClick={() => setActiveStrategy({ strategy: 'samlp' })}
                        disabled={!userEmail || (connection && isCreator)}>SAML Identity Provider
                </button>
                <button className={classNames(styles["protocol-button"], activeStrategy === 'pingfederate' && styles['active'])}
                        onClick={() => setActiveStrategy({ strategy: 'pingfederate' })}
                        disabled={!userEmail || (connection && isCreator)}>Ping Federation
                </button>
            </div>
        </>}
        {activeStrategy === 'adfs' &&
            <CreateAdfsConnectionComponent userEmail={userEmail} status={status} tenant={tenant} domain={domain}
                                           connection={connection}
                                           createConnection={createConnection} updateConnection={updateConnection}
                                           enableConnection={enableConnection}
                                           toggleUpdateMode={toggleUpdateMode}
                                           createConnectionError={createConnectionError}
                                           updateConnectionError={updateConnectionError}
                                           enableConnectionError={enableConnectionError}
                                           isCreator={isCreator}
                                           loading={loading}/>}
        {activeStrategy === 'waad' &&
            <CreateAzureAdConnectionComponent userEmail={userEmail} status={status} tenant={tenant} domain={domain}
                                              connection={connection}
                                              createConnection={createConnection} updateConnection={updateConnection}
                                              enableConnection={enableConnection}
                                              toggleUpdateMode={toggleUpdateMode}
                                              createConnectionError={createConnectionError}
                                              updateConnectionError={updateConnectionError}
                                              enableConnectionError={enableConnectionError}
                                              isCreator={isCreator}
                                              loading={loading}/>}
        {activeStrategy === 'samlp' &&
            <CreateSamlConnectionComponent userEmail={userEmail} status={status} tenant={tenant} domain={domain}
                                           connection={connection}
                                           createConnection={createConnection} updateConnection={updateConnection}
                                           enableConnection={enableConnection}
                                           toggleUpdateMode={toggleUpdateMode}
                                           createConnectionError={createConnectionError}
                                           updateConnectionError={updateConnectionError}
                                           enableConnectionError={enableConnectionError}
                                           isCreator={isCreator}
                                           loading={loading}/>}
        {activeStrategy === 'pingfederate' &&
            <CreatePingFederateConnectionComponent userEmail={userEmail} status={status} tenant={tenant} domain={domain}
                                                   connection={connection}
                                                   createConnection={createConnection} updateConnection={updateConnection}
                                                   enableConnection={enableConnection}
                                                   toggleUpdateMode={toggleUpdateMode}
                                                   createConnectionError={createConnectionError}
                                                   updateConnectionError={updateConnectionError}
                                                   enableConnectionError={enableConnectionError}
                                                   isCreator={isCreator}
                                                   loading={loading}/>}
    </div>);
};

class FederationSetup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.isSupport = "cc:federation-support" in props.scopes;
        if (!this.isSupport && this.props.authenticated) {
            this.props.getConnection({ email: this.props.authData.email });
        }
        this.createConnection = this.createConnection.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.supportEmail && this.props.supportEmail !== prevProps.supportEmail) {
            this.props.getConnection({ email: this.props.supportEmail })
        }
    }

    createConnection(params) {
        if (this.isSupport) {
            this.props.createConnection({ params, email: this.props.supportEmail })
        } else {
            this.props.createConnection({ params })
        }

    }

    render() {
        const isFederatedUser = !!this.props.authData['http://cloud.netapp.com/is_federated'];
        //showVerifyEmail is false for support and federated users
        const showVerifyEmail = !this.isSupport && !isFederatedUser && !this.props.connection && !this.props.loading &&
            this.props.authenticated && !this.props.authData.email_verified;

        return (
            <div className={styles['federation-setup']}>
                <CloudCentralSecondaryHeader title="Federation Setup" smartBack={true}/>
                {this.props.loading && <Loader/>}
                {this.props.getConnectionError &&
                    <div className={styles["main-view-error-message-container"]}>
                        <div className={styles["main-view-error-message"]}>An error occurred obtaining your connection data: {this.props.getConnectionError}</div>
                        {this.isSupport && <button className={buttons["primary"]} onClick={() => this.props.selectSupportEmail(null)}>Retry email</button>}
                    </div>}
                <div className={styles["main-content-container"]}>
                    {this.isSupport && !this.props.supportEmail &&
                        <SelectSupportEmail selectSupportEmail={this.props.selectSupportEmail}/>}
                    {this.props.status === "STARTED-BY-OTHER-USER" &&
                        <StartedByOtherUserComponent creatorUserInfo={this.props.connection}/>}
                    {this.props.status === "CONNECTION-ENABLED" &&
                        <ConnectionAlreadyEnabledComponent creatorUserInfo={this.props.connection}/>}
                    <CreateConnectionComponent userEmail={this.isSupport ? this.props.supportEmail : this.props.authData.email}
                                               connection={this.props.connection}
                                               status={this.props.status}
                                               createConnection={this.createConnection}
                                               updateConnection={this.props.updateConnection}
                                               enableConnection={this.props.enableConnection}
                                               setActiveStrategy={this.props.setActiveStrategy}
                                               activeStrategy={this.props.activeStrategy}
                                               toggleUpdateMode={this.props.toggleUpdateMode}
                                               createConnectionError={this.props.createConnectionError}
                                               updateConnectionError={this.props.updateConnectionError}
                                               enableConnectionError={this.props.enableConnectionError}
                                               isCreator={!this.props.connection || this.props.connection.creatorUsers.includes(this.props.userId)}
                                               loading={this.props.loading}
                                               showVerifyEmail={showVerifyEmail}
                                               resendVerificationEmail={this.props.resendVerificationEmail}
                                               resendVerificationEmailSuccess={this.props.resendVerificationEmailSuccess}
                                               resendVerificationEmailError={this.props.resendVerificationEmailError}/>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    resendVerificationEmail: () => {
        dispatch({
            type: "FEDERATION-SETUP:RESEND-VERIFICATION-EMAIL"
        });
    },
    getConnection: ({ email }) => {
        dispatch({
            type: "FEDERATION-SETUP:GET-CONNECTION",
            payload: { email }
        });
    },
    createConnection: ({ params, email }) => {
        dispatch({
            type: "FEDERATION-SETUP:CREATE-CONNECTION",
            payload: {
                params,
                email
            }
        });
    },
    updateConnection: payload => {
        dispatch({
            type: "FEDERATION-SETUP:UPDATE-CONNECTION",
            payload
        });
    },
    enableConnection: payload => {
        dispatch({
            type: "FEDERATION-SETUP:ENABLE-CONNECTION",
            payload
        });
    },
    setActiveStrategy: ({ strategy }) => {
        dispatch({
            type: "FEDERATION-SETUP:SET-ACTIVE-STRATEGY",
            payload: { strategy }
        });
    },
    toggleUpdateMode: () => {
        dispatch({
            type: "FEDERATION-SETUP:TOGGLE-UPDATE-MODE"
        });
    },
    selectSupportEmail: (email) => {
        dispatch({
            type: "FEDERATION-SETUP:SELECT-SUPPORT-EMAIL",
            payload: { email }
        });
    }
});

const mapStateToProps = state => ({
    authData: state.auth.data,
    scopes: state.auth.scopes,
    authenticated: state.auth.authenticated,
    userId: state.auth.userId,
    loading: state.federationSetup.loading,
    resendVerificationEmailSuccess: state.federationSetup.resendVerificationEmailSuccess,
    resendVerificationEmailError: state.federationSetup.resendVerificationEmailError,
    status: state.federationSetup.status,
    connection: state.federationSetup.connection,
    getConnectionError: state.federationSetup.getConnectionError,
    activeStrategy: state.federationSetup.activeStrategy,
    createConnectionError: state.federationSetup.createConnectionError,
    updateConnectionError: state.federationSetup.updateConnectionError,
    enableConnectionError: state.federationSetup.enableConnectionError,
    supportEmail: state.federationSetup.supportEmail
});

export default connect(mapStateToProps, mapDispatchToProps)(FederationSetup);
